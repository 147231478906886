<template>
  <v-container cols="12" class="pl-1 pr-1 mt-5 mb-5">
    <v-row>
      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('tools.client') }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.client.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('tools.cost') }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.cost }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('tools.ordered_at') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ toDateString(item.when_ordered) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('tools.delivered_at')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ toDateString(item.when_delivered) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('tools.created_at') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ toDateString(item.when_created) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('tools.supplier') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.supplier.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="6" md="6" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('tools.dimensions') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.dimension_x }} x {{ item.dimension_y }} x
              {{ item.dimension_z }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('tools.nesting') }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.nesting }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('tools.location') }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.location }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  },

  methods: {
    val(item, field) {
      let v = item[field];
      if (v && v.length > 0) {
        return v;
      }
      return '----';
    },
    toDateString(date) {
      return date.toLocaleDateString();
    }
  }
};
</script>

<style></style>
